import React from 'react';
// import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
// import 'react-vertical-timeline-component/style.min.css';
// import experiences from '../data/experiences.json';
// import contactStyles from '../styles/contact.module.scss';

const Contact = () => {
    return (
        <div>
            {/* <VerticalTimeline>
                {
                    experiences.companies.map((company) => {
                        return (
                            <VerticalTimelineElement
                                className={contactStyles.work}
                                iconClassName='vertical-timeline-element__icon'
                                dateClassName='vertical-timeline-element__date'
                                // contentStyle={{background: 'rgb(33, 150, 243)', color: '#000', borderBottom: 'blue'}}
                                contentArrowStyle={{borderRight: '7px solid  rgb(33, 150, 243)'}}
                                date="2010 - 2012"
                                iconStyle={{background: 'rgb(33, 150, 243)', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center'}}
                                icon={<img src={company.logo} style={{height: '3rem', width: '3rem'}} />}
                            >
                                <h3>{company.name}</h3>
                            </VerticalTimelineElement>
                        );
                    })
                }
            </VerticalTimeline> */}
        </div>
    );
}

export default Contact;
